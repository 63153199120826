import * as React from "react"
import styled from "styled-components"


const partners = [
    {
        icon: '',
        text: ''
    }
]

export const PartnersBlock = ({partners}) => {
    return (
        <Wrapper>
            <div className={'title'}>Discounts provided by our partners</div>

            <div className={'partners-list'}>
                {
                    partners?.map((partner, index) =>(
                        <div className={'partner'} key={partner?.id}>
                            <a href={partner?.ulr} target={'_blank'} className={'logo'}>
                                <img src={partner?.partnerImage?.data?.attributes?.url}/>
                            </a>
                            <div className={'description'}>{partner?.description}</div>
                        </div>
                    ))
                }
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-bottom: 20px;
  .title{
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    text-align: center;
    letter-spacing: 0.01em;

    color: #FFFFFF;
  }
  
  .partners-list{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  
  .partner {
    margin-bottom: 15px;
    
    .logo{
      width: 230px;
      height: 38px;
      box-sizing: border-box;
      display: block;
      margin: auto;
      margin-bottom: 20px;
      
    }
    .description{
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #B5CBEA;
    }
  }
`;
