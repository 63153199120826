import * as React from "react"
import styled from "styled-components"
import {OptionButton} from "../atoms/OptionButton";
import {ButtonAtom} from "../atoms/ButtonAtom";
import {useMemo, useState} from "react";


export const PoolElement = ({config, onReady = () => {}}) => {
    const [answers, setAnswer] = useState({});
    const [currentPage, setPage] = useState(0);

    const configSet = useMemo(() => config != null ? config[currentPage] : null, [config, currentPage]);

    return (
        <Wrapper>
            <div className={'title'}>
                {configSet?.title || 'demo title'}
            </div>
            <div className={'options-container'}>
                {configSet?.options?.map((option, index) =>
                    <div className={'el'}> <OptionButton onClick={() => {
                        let s = answers[configSet?.title] || [];
                        let exist = s?.findIndex((s) => s == option);
                        if(exist > -1){
                             s.splice(exist, 1)
                        }else{
                            s.push(option)
                        }
                        setAnswer({
                            ...answers,
                            [configSet?.title]: s
                        })
                    }} active={answers[configSet?.title]?.includes(option)} value={option}/></div>)
                }
            </div>

            <div className={'actions'}>
                <ButtonAtom
                    fullWidth={true}
                    disiabled={!answers[configSet?.title]?.length}
                    onClick={() => {
                        if(currentPage < config?.length-1){
                            setPage(currentPage+1);
                        }else {
                            onReady(answers);
                        }

                    }}
                    title={answers[configSet?.title]?.length ? 'Next' : 'Choose at least one option'}/>
            </div>
        </Wrapper>
    )
}



const Wrapper = styled.div`
  .title{
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
  }
  
  .options-container{
    .el{
      margin-bottom: 10px;
    }
    margin-top: 30px;
  }
  
  .actions{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;
