import * as React from "react"
import styled from "styled-components"
import ProxyLogo from "../../assets/iProxy_logo_forDarkBG.svg";
import {ButtonAtom} from "../atoms/ButtonAtom";

export const ThankYou = ({promo}) => {
    return (
        <Wrapper>
            <div className={'logo'}>
                <img src={ProxyLogo} alt="iProxy.online"/>
            </div>
            <div className={'title'}>
                Thank you for registration
            </div>
            <div className={'info'}>
                <div>Save you promo code for future use.</div>
                <div className={'promo'}> {promo}</div>
            </div>
            <div className={'actions'}>
                <ButtonAtom
                    fullWidth
                    isClear
                    title={'Go to main Website'} onClick={() => window.location.href = '/'}/>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  .promo {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #fff;
    width: fit-content;
    margin: 20px auto;
    border-radius: 5px;
  }

  .info {
    font-weight: 500;
    font-size: 24px;
    line-height: 45px;
    letter-spacing: 0.01em;
    margin-top: 50px;
    color: #FFFFFF;
    text-align: center;
  }

  .logo {

  }

  .actions {
    margin-top: 150px;
  }

  .title {
    font-weight: 800;
    font-size: 32px;
    line-height: 45px;
    letter-spacing: 0.01em;
    margin-top: 50px;
    color: #FFFFFF;
  }
`;
