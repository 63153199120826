import * as React from "react"
import styled from "styled-components"

export const OptionButton = ({value, onClick = () => {},  active}) => {
    return (
        <Wrapper onClick={() => onClick()} active={active}>
            {value}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: fit-content;
  border: 1px solid #FF2F5B;
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  gap: 10px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  color: #FFFFFF;
  
  background-color: ${(props) => props.active ? '#FF2F5B' : 'transparent'};
  
  :hover{
    opacity: .8;
  }

  transition: .2s;
`;
